import * as React from 'react';
import { render } from 'react-dom';
import { IOverviewProps } from "./IOverviewProps";
import { IOverviewState } from "./IOverviewState";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';

const columns = [
    { id: 'name', label: 'Name'},
    { id: 'code', label: 'Code'},
    { id: 'type', label: 'Type'},
    {
        id: 'openfile',
        label: 'Open'
    }
];

export class Overview extends React.Component<IOverviewProps, IOverviewState> {
    constructor(props: IOverviewProps) {
        super(props);
        this.state = {
            page: 0,
            itemsPerPage:10
        }
    }

    public componentDidUpdate(prevProps: IOverviewProps) {
        if (prevProps.filteredBlobItems !== this.props.filteredBlobItems) {
            this.setState({
                page:0
            })
        }
    }
    public onPageChange(event: any, newPage: number) {
        this.setState({
            page: newPage
        });
    }

    public onItemsPerPageChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            page: 0,
            itemsPerPage: Number(event.target.value)
        })
    }
    public async getBlockBlobURL(fileName: string) {

        const body = JSON.stringify({ blobContainerName: 'materieelbeheer', blobName: fileName });
        const customConfig = {
            headers: { 'Content-Type': 'application/json' }
        };
        const response = await axios.post("api/BlobStorage/GetUserDelegatedSAS", body, customConfig);
        window.open(response.data, '_blank');
    }

    render() {
        return (
            <>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.filteredBlobItems
                                .slice(this.state.page * this.state.itemsPerPage, this.state.page * this.state.itemsPerPage + this.state.itemsPerPage)
                                .map((row, i) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={"DCA_ID_"+i}>
                                            <TableCell>
                                                {row.fileName}
                                            </TableCell>
                                            <TableCell>
                                                {row.code}
                                            </TableCell>
                                            <TableCell>
                                                {row.type}
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    onClick={() => {
                                                        this.getBlockBlobURL(row.url)
                                                    }}
                                                >
                                                    Open
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={this.props.filteredBlobItems.length}
                    rowsPerPage={this.state.itemsPerPage}
                    page={this.state.page}
                    onPageChange={this.onPageChange.bind(this)}
                    onRowsPerPageChange={this.onItemsPerPageChange.bind(this)}
                />
            </>
        )
    }
}