import * as React from 'react';
import { ISearchBarProps } from './ISearchBarProps';
import { ISearchBarState } from './ISearchBarState';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import SearchIcon from '@mui/icons-material/Search';
import { Overview } from '../Overview/Overview';
import axios from 'axios';

export class SearchBar extends React.Component<ISearchBarProps, ISearchBarState> {
    constructor(props: ISearchBarProps) {
        super(props);
        this.state = {
            searchTerm: "",
            filteredBlobItems: [],
            allBlobItems: [],
            searchQueryExecuted: false
        }
    }

    public async componentDidMount() {
        const response = await axios.get("api/BlobStorage/FilterBlobItems?refresh=true");
        this.setState({
            allBlobItems: response.data
        })
    }

    public setSearchTerm(event: any) {
        this.setState({
            searchTerm: event.target.value
        });
    }

    public async searchFilesOnEnter(event: any) {
        if (event.key === "Enter") {
            this.searchFiles();
        }
    }

    public async searchFiles() {
        let blobFiles = [];
        let searchTerm = this.state.searchTerm.slice();

        if (searchTerm != null && searchTerm !== "") {
            let response = await axios.get("api/BlobStorage/FilterBlobItems?searchTerm=" + searchTerm);
            blobFiles = response.data;
        }

        this.setState({
            filteredBlobItems: blobFiles,
            searchTerm: searchTerm,
            searchQueryExecuted: true
        })
    }

    render() {
        return (
            <>
                <div className="dca_SearchBarZone">
                    <TextField id="dca_SearchBar" label="" type="text" variant="outlined" onInput={this.setSearchTerm.bind(this)} onKeyDown={this.searchFilesOnEnter.bind(this)} placeholder="Zoek" />
                    <Button id="dca_SearchBarButton" variant="outlined" startIcon={<SearchIcon />} onClick={this.searchFiles.bind(this)}>
                        Zoeken
                    </Button>
                </div>
                {this.state.filteredBlobItems.length > 0 ?
                    <div className="dca_OverviewZone">
                        <Overview filteredBlobItems={this.state.filteredBlobItems} />
                    </div>
                    :
                    this.state.searchQueryExecuted && this.state.searchTerm !== "" &&
                    <div className="dca_NoItemAlert">
                        <Alert severity="error" color="error">Geen overeenkomstige items gevonden!</Alert>
                    </div>
                }
            </>
        )
    }
}